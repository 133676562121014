import React from "react";
import "./AboutHome.scss";
import HomeAbout from "../../../Images/HomeAboutImg.png";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";

const AboutHome = () => {
  const { t } = useTranslation();
  return (
    <div>
      <section className="home-about">
        <div className="home-about-container">
          <div className="home-about-content reveal">
            {" "}
            <Fade left cascade distance="20%">
              <div className="home-about-wrapper">
                <h2 className="home-about-title">
                  {t("home:about.title.1")}
                  <span className="home-about-title-gray">
                    {" "}
                    | {t("home:about.title.2")}{" "}
                  </span>
                </h2>
                <p className="home-about-text">{t("home:about.text")}</p>
                <div className=" home-about-btn">
                  <a href="/About">
                    <button className="btn1">{t("btn.1")}</button>
                  </a>
                </div>
              </div>
            </Fade>
          </div>

          <div className="home-about-image">
            <img src={HomeAbout} alt="" />
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutHome;
