import React from "react";
import "./ProductHome.scss";
import powder from "../../../Images/GypsumPowderCardImg.png";
import stone from "../../../Images/GypsumStoneCardImg.png";
import plaster from "../../../Images/PlasterBoardCardImg.jpg";
import steel from "../../../Images/SteelCardImg.png";
import { AiOutlineCaretRight } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";

const ProductHome = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Fade bottom cascade distance="15%">
        <section className="product-header-wrap">
          <div className="product-header ">
            <h2 className="prod-header">
              {" "}
              {t("home:product.title.topL")}{" "}
              <span className="prod-header-gray">
                {" "}
                | {t("home:product.title.topR")}
              </span>
            </h2>
            <p className="prod-text">{t("home:product.text.top")}</p>
          </div>
        </section>
      </Fade>

      <section className="poduct-overview-cards reveal">
        <Fade bottom cascade distance="15%">
          <div className="product-cards">
            <div className="gypsum-product-card">
              <div className="prod-card-img">
                <img src={plaster} alt="" />
              </div>
              <div className="prod-card-content">
                <h4 className="prod-card-title">
                  {t("home:product.cards.1.title")}
                </h4>
                <p className="prod-card-text">
                  {t("home:product.cards.1.text")}
                </p>
                <div className="prod-card-link-icon">
                  <span className="link-icon">
                    <AiOutlineCaretRight size={20} color="#083e89" />
                  </span>
                  <a
                    href="/PlasterBoard"
                    className="prod-card-link"
                  >
                    {t("btn.1")}
                  </a>
                </div>
              </div>
            </div>
            <div className="gypsum-product-card">
              <div className="prod-card-img">
                <img src={powder} alt="" />
              </div>
              <div className="prod-card-content">
                <h4 className="prod-card-title">
                  {t("home:product.cards.2.title")}
                </h4>
                <p className="prod-card-text">
                  {t("home:product.cards.2.text")}
                </p>
                <div className="prod-card-link-icon">
                  <span className="link-icon">
                    <AiOutlineCaretRight size={20} color="#083e89" />
                  </span>
                  <a
                    href="/Powder"
                    className="prod-card-link"
                  >
                    {t("btn.1")}
                  </a>
                </div>
              </div>
            </div>
            <div className="gypsum-product-card">
              <div className="prod-card-img">
                <img src={steel} alt="" />
              </div>
              <div className="prod-card-content">
                <h4 className="prod-card-title">
                  {t("home:product.cards.3.title")}
                </h4>
                <p className="prod-card-text">
                  {t("home:product.cards.3.text")}
                </p>
                <div className="prod-card-link-icon">
                  <span className="link-icon">
                    <AiOutlineCaretRight size={20} color="#083e89" />
                  </span>
                  <a
                    href="/SteelKeel"
                    className="prod-card-link"
                  >
                    {t("btn.1")}
                  </a>
                </div>
              </div>
            </div>
            <div className="gypsum-product-card">
              <div className="prod-card-img">
                <img src={stone} alt="" />
              </div>
              <div className="prod-card-content">
                <h4 className="prod-card-title">
                  {t("home:product.cards.4.title")}
                </h4>
                <p className="prod-card-text">
                  {t("home:product.cards.4.text")}
                </p>
                <div className="prod-card-link-icon">
                  <span className="link-icon">
                    <AiOutlineCaretRight size={20} color="#083e89" />
                  </span>
                  <a
                    href="/PlasterBlock"
                    className="prod-card-link"
                  >
                    {t("btn.1")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </section>
    </div>
  );
};

export default ProductHome;
