import React from "react";
import "./Whyus.scss";
import WhyUs from "../../../Images/why-us-img.png";
import { GiHammerNails } from "react-icons/gi";
import { GrTechnology } from "react-icons/gr";
import { BsTools } from "react-icons/bs";
import { BsPersonGear } from "react-icons/bs";
import { TbChartArrowsVertical } from "react-icons/tb";
import { RiToolsFill } from "react-icons/ri";
import { useTranslation } from 'react-i18next';
import Fade from "react-reveal/Fade";

const Whyus = () => {
  const { t } = useTranslation();
  return (
    <div>
      {" "}
      <section>
        <div className="why-us-container">
          <div className="why-us-img-container">
            <img className="why-us-img" src={WhyUs} alt="why choose us" />
          </div>
          <div className="why-us-content reveal">
            <Fade right cascade distance="10%">
              <div className="why-us-wrapper why-us-wrapper-pt">
                <h4 className="why-us-intro">{t('home:whyus.title.1')}</h4>
                <h2>{t('home:whyus.title.2')}</h2>
              </div>
            </Fade>
            <Fade right cascade distance="20%">
              <div className="why-us-list-container list-pt">
                <div className="why-us-list">
                  <span>
                    <GiHammerNails size={28} color="white" className="why-us-icon" />
                  </span>
                  <p>
                  {t('home:whyus.text.1')}
                  </p>
                </div>
                <div className="why-us-list list-pt">
                 <span>
                   <TbChartArrowsVertical size={28}  color="white" className="why-us-icon" />
                 </span>
                  <p>
                  {t('home:whyus.text.2')}
                  </p>
                </div>
                <div className="why-us-list list-pt">
                  <span className="why-us-icon">
                    <BsPersonGear size={28}  color="white" className="why-us-icon" />
                  </span>
                  <p>
                  {t('home:whyus.text.3')}
                  </p>
                </div>
                <div className="why-us-list list-pt">
                <span>
                  <RiToolsFill size={28}  color="white" className="why-us-icon"/>
                </span>
                  <p>
                  {t('home:whyus.text.4')}
                  </p>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Whyus;
